<template>
    <div class="pageContol listWrap templateList">
        <div class="framePage">
            <div class="framePage-title">
                <span>
                    <em>当前位置：</em>
                    <a href="javascript:;">数据中心</a>
                    <i>></i>
                    <a href="javascript:;" class="cur-a">学员报名统计</a>
                </span>
            </div>
            <div class="framePage-body">
                <!-- 搜索区域 -->
                <div class="operationControl" style="display: flex; flex-direction: column; align-items: flex-start">
                    <div class="searchbox ">
                        <div title="姓名" class="searchboxItem ci-full">
                            <span class="itemLabel">姓名:</span>
                            <el-input v-model="searchForm.userName" placeholder="请输入姓名" type="text" clearable size="small"/>
                        </div>
                        <div title="联系方式" class="searchboxItem ci-full">
                            <span class="itemLabel">联系方式:</span>
                            <el-input v-model="searchForm.mobile" placeholder="请输入联系方式" type="text" clearable size="small"/>
                        </div>
                        <div title="招生渠道" class="searchboxItem ci-full">
                            <span class="itemLabel">招生渠道:</span>
                            <el-select size="small" clearable v-model="searchForm.channelId" placeholder="请选择招生渠道">
                                <el-option
                                    v-for="item in contactUserList"
                                    :key="item.channelId"
                                    :label="item.contactUser"
                                    :value="item.channelId">
                                </el-option>
                            </el-select>
                        </div>
                        <div title="招生任务名称" class="searchboxItem ci-full">
                            <span class="itemLabel">招生任务名称:</span>
                            <el-select
                                v-model="searchForm.projectId"
                                filterable
                                clearable
                                remote
                                size="small"
                                placeholder="请至少输入两字搜索"
                                :remote-method="getProjectList"
                                >
                                <el-option
                                    v-for="item in projectList"
                                    :key="item.projectId"
                                    :label="item.projectName"
                                    :value="item.projectId"
                                    >
                                </el-option>
                            </el-select>
                        </div>
                    </div>
                    <div class="searchbox" style="margin-top:20px;">
                        <div title="审核状态" class="searchboxItem ci-full">
                            <span class="itemLabel">审核状态:</span>
                            <el-select size="small" clearable v-model="searchForm.auditState" placeholder="请选择审核状态">
                                <el-option
                                    v-for="item in auditStateDic"
                                    :key="item.value"
                                    :label="item.label"
                                    :value="item.value">
                                </el-option>
                            </el-select>
                        </div>
                      <div title="所在区域" class="searchboxItem ci-full">
                        <span class="itemLabel">所在区域:</span>
                        <el-cascader
                            v-model="searchForm.areaNameList"
                            placeholder="请选择所在区域"
                            :options="areaTreeList"
                            :props="propsArea"
                            :clearable="true"
                            @change="areaChange"
                            size="small"
                        ></el-cascader>
                      </div>
                        <div class="searchboxItem">
                            <el-button type="primary" class="bgc-bv" round @click="getListData(1)">查询</el-button>
                        </div>
                        <div class="searchboxItem">
                            <el-button type="primary" class="bgc-bv" round @click="exportData">导出</el-button>
                        </div>
                    </div>
                </div>

                <!-- 列表区域 -->
                <div class="framePage-scroll">
                    <div class="ovy-a">
                        <el-table
                            ref="multipleTable"
                            :data="listData"
                            :height="tableHeight"
                            size="small"
                            tooltip-effect="dark"
                            style="width: 100%"
                            :header-cell-style="tableHeader"
                            stripe
                        >
                            <el-table-column
                                label="序号"
                                align="center"
                                type="index"
                                :index="indexMethod"
                                width="50"
                            />
                            <el-table-column
                                label="姓名"
                                prop="userName"
                            />
                            <el-table-column
                                label="联系方式"
                                prop="mobile"
                                min-width="100"
                            />
                            <el-table-column
                                label="招生任务名称"
                                prop="projectName"
                                min-width="150"
                            />
                            <el-table-column
                                label="所在区域"
                                prop="areaName"
                                min-width="150"
                            />
                            <el-table-column
                                label="报名方式"
                                prop="joinType">
                                <template v-slot="{row}">
                                    {{$setDictionary("CT_JOIN_TYPE", row.joinType)}}
                                </template>
                            </el-table-column>
                            <el-table-column
                                label="招生渠道"
                                prop="contactUser"
                            />
                            <el-table-column
                                label="报名日期"
                                prop="createTime"
                                min-width="150"
                            />
                            <el-table-column
                                label="审核状态"
                                prop="auditState">
                                <template v-slot="{row}">
                                    {{$setDictionary("CT_USER_AUDIT_STATE", row.auditState)}}
                                </template>
                            </el-table-column>
                            <el-table-column
                                label="备注"
                                prop="auditReason"
                                min-width="100"
                            />
                            <el-table-column
                                label="操作"
                                align="center"
                                fixed="right">
                                <template v-slot="{row}">
                                    <el-button type="text" size="mini" style="padding: 0 5px;" @click="view(row)">查看</el-button>
                                </template>
                            </el-table-column>
                            <Empty slot="empty" />
                        </el-table>
                    </div>
                </div>

                <!-- 分页器 -->
                <PageNum :apiData="pagination" @sizeChange="sizeChange" @getData="currentChange"/>
            </div>
        </div>
    </div>
</template>

<script>
    import PageNum from "@/components/PageNum.vue";
    import List from "@/mixins/List";
    import Empty from "@/components/Empty.vue"
    import {mapGetters} from "vuex";
    import { resetKeepAlive } from "@/utils/common";
    export default {
        name:"StudentRegistrationStatistics",
        mixins:[List],
        components:{
            PageNum,
            Empty
        },
        data(){
            return{
                // 查询表单
                searchForm:{
                    channelId:"", // 招生渠道 0为自招
                    mobile:"",// 联系方式
                    projectId:"", // 培训id
                    userName:"",//姓名
                    auditState:"",// 审核状态
                  areaName:"",
                  areaNameList:[],
                },
                // 分页器数据
                pagination:{
                    total: 0,//总条数
                    size: 10,//每页几条数据
                    current: 1//当前页
                },
                // 表格数据
                listData:[],
                // 招生渠道下拉数据
                contactUserList:[],
                // 培训下拉数据
                projectList:[],
                // 审核状态字典数据
                auditStateDic:[],
              areaTreeList: [], //行政区划
              propsArea: {
                value: "label",
                label: "label",
                emitPath: true,
                checkStrictly: true,
              },
            }
        },
        computed: {
            ...mapGetters({
                downloadItems: "getDownloadItems",
            }),
        },
        watch:{
            $route(newRoute,oldRoute){
                if(newRoute.path==="/web/operate/stuRegSta" && oldRoute.path==="/web/studentDetails"){
                    this.getListData();
                }
            }
        },
        methods:{
          // 序号
          indexMethod(index) {
            return (this.pagination.current - 1) * this.pagination.size + index + 1;
          },
            // 初始化数据
            initData(){
                this.getListData();
                this.getTeacher();
                this.getAuditStateDic();
            },
            // 获取数据
            getListData(currentPage){
                const {pagination,searchForm}=this;
                if(currentPage){
                    pagination.current=currentPage;
                }
                const params=JSON.parse(JSON.stringify(searchForm));
                this.$post("/biz/ct/datacenter/pageList",{
                    pageNum:pagination.current,
                    pageSize:pagination.size,
                    ...params
                }).then(res=>{
                    this.listData=res?.data?.list||[];
                    pagination.total=res?.data?.total||0;
                }).catch(e=>{
                    console.error("获取列表数据出错",e);
                });
            },
            // 导出
            exportData(){
                const {searchForm}=this;
                const params=JSON.parse(JSON.stringify(searchForm));
                this.$post("/biz/ct/datacenter/listExport",params).then((res) => {
                    const arr=[res.data];
                    for (let item of arr) {
                        if (!this.downloadItems.includes(item.taskId)) {
                            this.$store.dispatch("pushDownloadItems", item.taskId);
                        } else {
                            this.$message.warning(
                                "[" + item.fileName + "]已经申请下载,请耐心等待"
                            );
                        }
                    }
                });
            },
            // 查看
            view(row){
                this.$router.push({
                    path:"/web/studentDetails",
                    query:{
                        origin:"学员报名统计",
                        projectId:row.projectId,
                        projectName:row.projectName,
                        userId:row.userId,
                        contactUser:row.contactUser,
                        type:"view"
                    }
                });
            },
            // 培训搜索方法
            getProjectList(query){
                if(query.trim().length<2){
                    return;
                }
                this.$post("/biz/ct/project/ctProjectListNoPage",{
                    projectName:query
                }).then(res=>{
                    this.projectList=res?.data||[];
                });
            },
            // 获取招生渠道下拉数据
            getTeacher(){
                this.$post("/biz/ct/channel/ctChannelSelectList",{}).then(res=>{
                    this.contactUserList=res.data||[];
                    this.contactUserList.unshift({
                        channelId:"0",
                        contactUser:"自招"
                    });
                }).catch(e=>{
                    console.error("获取招生数据出错",e);
                });
            },
            // 获取审核状态字典数据
            getAuditStateDic(){
                const obj=this.$setDictionary("CT_USER_AUDIT_STATE","list");
                const arr=[];
                for(let key in obj){
                    arr.push({
                        label:obj[key],
                        value:key
                    });
                }
                this.auditStateDic=arr;
            },
            // 分页器当前页改变
            currentChange(current){
                const {pagination}=this;
                pagination.current=current;
                this.getListData();
            },
            // 分页器每页条数改变
            sizeChange(size){
                const {pagination}=this;
                pagination.size=size;
                this.getListData();
            },
            // 表格高度
            getTableHeight() {
                let tHeight = window.innerHeight - 310;
                this.tableHeight = tHeight;
            },
          //获取行政区划
          getAreaTree() {
            this.$post("/sys/area/tree").then((ret) => {
              this.areaTreeList = ret.data;
            });
          },
          areaChange(data) {
            this.searchForm.areaName = data.join('-');
          }
        },
        created(){
          this.getAreaTree();
            this.initData();
        },
        beforeRouteLeave: resetKeepAlive,
    }
</script>

<style scoped lang="less">

</style>